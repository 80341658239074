<template>
    <div class="footer p-3 pb-0 pt-3 pt-md-0 d-flex justify-content-center position-relative">
        <div class="container-inputs p-0 p-md-3 m-0">
            <div class="row justify-content-center">
                <div class="col">
                    <!-- <h5>Heb je ons nodig?</h5> -->
                    <p class="my-0 text-md-center text-start"><small>Kom je er niet uit? Stuur een mail naar <a href="mailto:administratie@zelfstandigondernemers.nl">administratie@zelfstandigondernemers.nl</a></small></p>
                </div>
            </div>

            <div class="hr-small-border"></div>

            <div class="row justify-content-center">
                <p class="smaller text-md-center text-start">© 2024 Zelfstandig Ondernemers B.V. statutair gevestigd te Hilversum, KvK nr
                    82551588.<br>Alle rechten voorbehouden</p>
            </div>

            <div class="footer footer-filler position-absolute"></div>
        </div>
    </div>
</template>

<style scoped>
.footer {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.footer-filler {
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 1;
    position: fixed;
}

.smaller {
    font-size: 9px;
    text-align: center;
}

.hr-small-border {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.07);
}
</style>

<script type="text/javascript">
export default {
    name: 'footer-component',
}
</script>
<template>
    <div class="w-100 m-0 home-container d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">

            <div class="position-relative w-100 mb-2 mb-md-5">
                <h4 class="display-6 text-white fw-bold small-on-mobile title">{{ title ?? '&nbsp;' }}</h4>
                <h5 class="text-white fw-thin small-on-mobile title">{{ username ?? '&nbsp;' }}</h5>

                
                <form method="post" enctype="multipart/form-data" class="d-inline-block position-absolute end-0 top-0 gravatar-container text-align-center">
                    <img :src="showAvatar" class="gravatar">
                    <input id="avatarUpload" type="file" @change="onChangeAvatarUpload" accept="image/*,android/force-camera-workaround">
                    <label for="avatarUpload" class="overlay-change-avatar" @drop.prevent="onDropAvatar" @dragenter.prevent="onDragenterAvatar" @dragleave.prevent="onDragleaveAvatar">
                        <i class="fa fa-edit pos-center pe-none display-6"></i>
                    </label>
                </form>
            </div>

            <div class="wait-icon-container" v-if="loadingCustomerdata">
                <svg width="300" height="300" class="wait-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path class="spinner_l9ve"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                    <path class="spinner_l9ve spinner_cMYp"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                    <path class="spinner_l9ve spinner_gHR3"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                </svg>
            </div>

            <!-- Blokken -->
            <div id="sitemap">
                <div id="content">
                    <div id="allBlocks" class="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 p-2 position-relative">
                        <!-- Saldo -->
                        <div class="col square p-1">
                            <router-link to="/saldo" class="text-decoration-none">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">{{ isCrowdsurance() ? 'Mijn buffer' : 'Mijn spaarpot' }}</h2>
                                    </div>
                                    <p class="small mb-1 smaller-text">Huidig saldo</p>
                                    <h3 class="mb-0 balance p-1 rounded-1 bg-dark-blue d-inline-block fw-normal dashboard-data title">
                                        {{ dashboardData.saldo !== undefined ? this.$numberFormatEuro(dashboardData.saldo) : '' }}</h3>
                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_wallet.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <!-- Ziekte percentage (algemene informatie) -->
                        <div class="col square p-1">
                            <router-link to="/ao-percentage" class="text-decoration-none">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">Statistieken</h2>
                                    </div>

                                    <div class="w-100">
                                        <p class="small fw-normal mb-0 smaller-text">Actueel ziektepercentage</p>
                                        <h4 class="display-6 fw-normal d-inline-block dashboard-data">{{ dashboardData && dashboardData.current_effective_disability_percentage ? dashboardData.current_effective_disability_percentage.toString().replaceAll(".", ",") : '' }}&percnt;</h4>
                                    </div>

                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_percentage.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <!-- Schenkingen laatste periode -->
                        <div class="col square p-1">
                            <router-link to="/gifts" style="text-decoration: none !important">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">Schenkingen</h2>
                                    </div>
                                        <p class="small fw-normal mb-0 smaller-text">Laatste periode</p>
                                    <h4 class="mb-0 display-6 fw-normal dashboard-data">
                                        {{ dashboardData && !isNaN(dashboardData.last_period_gifts) ? $numberFormatEuro(dashboardData.last_period_gifts) : '' }}
                                    </h4>
                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_gift.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <!-- Mijn producten -->
                        <div class="col square p-1">
                            <template v-if="!isCrowdsurance()">
                                <router-link to="/products">
                                    <div class="block p-3">
                                        <div class="text-center bottom-text w-100">
                                            <h2 class="fw-bold smaller-h2">Mijn producten</h2>
                                        </div>
                                        <div class="icon-container">
                                            <!-- <img src="@/assets/dashboard/ic_boxes.svg" class="block-backdrop"> -->
                                            <div class="d-flex flex-wrap p-4 m-0 top-0 position-absolute h-100 w-100">
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-bandage.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-brokenleg.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-oldman.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-piggy.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </router-link>
                            </template>
                            <template v-else>
                                <router-link to="/products-crowdsurance">
                                    <div class="block p-3">
                                        <div class="text-center bottom-text w-100">
                                            <h2 class="fw-bold smaller-h2">Crowdsurance</h2>
                                        </div>
                                        <div class="icon-container">
                                            <!-- <img src="@/assets/dashboard/ic_boxes.svg" class="block-backdrop"> -->
                                            <div class="d-flex flex-wrap p-4 m-0 top-0 position-absolute h-100 w-100">
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-bandage.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-brokenleg.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-oldman.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                                <div class="w-50 p-1 position-relative">
                                                    <img src="@/assets/icon-piggy.svg" class="w-100 h-100 small-icon start-0 top-0 position-absolute">
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </router-link>
                            </template>
                        </div>

                        <!-- Facturen -->
                        <div class="col square p-1 position-relative">
                            <router-link to="/invoices" class="position-relative" style="text-decoration: none !important">
                                <div class="block p-3">
                                    <div>
                                        <h6 class="fw-bold lead">Nieuwste factuur</h6>
                                        <h6 class="small fw-bold mb-0 dashboard-data">{{ dashboardData && dashboardData.last_invoice ? $formatDate(dashboardData.last_invoice.invoice_at) : '' }}</h6>
                                    </div>
                                    <!-- <p class="text-danger small">2 openstaande facturen</p> -->
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">Administratie-<br/>overzicht</h2>
                                </div>
                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_list.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                            <span :class="['notif', isCrowdsurance() ? 'crowdsurance-notif' : '']" v-if="dashboardData && dashboardData.count_new_invoices > 0 || dashboardData.count_open_directdebits">{{ dashboardData.count_new_invoices + dashboardData.count_open_directdebits }}</span>
                        </div>
                        
                        <!-- Arbeidsongeschiktheid -->
                        <div class="col square p-1 position-relative">
                            <router-link class="position-relative" to="/disability">
                                <div class="block p-3 h-100 position-relative">
                                    <p class="fw-normal smaller-text mb-1">Arbeidsongeschiktheid</p>
                                    <div class="text-center bottom-text w-100 px-1">
                                        <h2 class="fw-bold smaller-h2">Mijn<br/>ziekmeldingen</h2>
                                    </div>
                                    <div class="icon-container">
                                        <img src="@/assets/icon-brokenleg.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                            <span :class="['notif', isCrowdsurance() ? 'crowdsurance-notif' : '']" v-if="dashboardData.count_update_expected_disabilities > 0">{{ dashboardData.count_update_expected_disabilities }}</span>
                        </div>

                        <!-- Nieuws -->
                        <div class="col square p-1">
                            <router-link to="/news" class="text-decoration-none">
                                <div class="block p-3">
                                    <p class="small smaller-text mb-0 dashboard-data">
                                        {{ dashboardData && dashboardData.last_news ? $formatDate(dashboardData.last_news.publish_at) : "" }}
                                    </p>
                                    <p class="small fw-bold text-decoration-none smaller-text dashboard-data">
                                        {{ dashboardData && dashboardData.last_news ? dashboardData.last_news.title : "" }}
                                    </p>
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">Nieuws</h2>
                                    </div>
                                    <div class="icon-container">
                                        <!-- <i class="fa fa-book icon"></i> -->
                                        <img src="@/assets/dashboard/ic_book.svg" class="block-backdrop">
                                    </div>
                                    <span :class="['notif', isCrowdsurance() ? 'crowdsurance-notif' : '']" v-if="dashboardData && dashboardData.count_new_news_articles > 0">{{ dashboardData.count_new_news_articles }}</span>
                                </div>
                            </router-link>
                        </div>

                        <!-- Berichten-->
                        <div class="col square p-1">
                            <router-link to="/messages">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">Berichten</h2>
                                    </div>
                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_envelope.svg" class="block-backdrop">
                                    </div>
                                    <span :class="['notif', isCrowdsurance() ? 'crowdsurance-notif' : '']" v-if="dashboardData && dashboardData.count_new_messages > 0">{{ dashboardData.count_new_messages }}</span>
                                </div>
                            </router-link>
                        </div>

                        <!-- Account -->
                        <!-- <div class="col square p-1">
                            <router-link to="/account">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold">Account</h2>
                                    </div>
                                    <div class="icon-container">
                                        <img src="../assets/block_icons/key.jpg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                        </div> -->

                        <!-- Personalia -->
                        <div class="col square p-1">
                            <router-link to="/personalia">
                                <div class="block p-3 h-100 position-relative">
                                    <div class="text-center bottom-text w-100">
                                        <h2 class="fw-bold smaller-h2">NAW<br/>gegevens</h2>
                                    </div>
                                    <div class="icon-container">
                                        <img src="@/assets/dashboard/ic_user.svg" class="block-backdrop">
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.small-h2 {
    font-size: 20px;
    font-weight: bold;
}

.smallest-text {
    font-size: 12px;
}

.filler-1em {
    height: 1em;
}

.subtitle {
    font-size: 12px;
    margin: 0;
}

.small-icon {
    transform: scale(0.8);
}

@media screen and (max-width: 200px) {
    .gravatar{
        display: none !important;
    }   
}

@media screen and (max-width: 768px) {
    .small-on-mobile {
        font-size: calc(6px + 3vw);
    }

    .gravatar-container {
        width: 64px !important;
        height: 64px !important;
    }
}

.transparent {
    background-color: transparent;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.stick-bottom {
    position: absolute !important;
    bottom: 0;
}

.gravatar-container {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
}

.wait-icon {
    color: #fff !important;
    position: absolute;
    z-index: 999;
    filter: invert();
    left: calc(50% - 150px);
    top: calc(50% - 150px);
}

.wait-icon-container {
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
}

.spinner_l9ve {
    animation: spinner_rcyq 1.2s cubic-bezier(0.52, .6, .25, .99) infinite
}

.spinner_cMYp {
    animation-delay: .4s
}

.spinner_gHR3 {
    animation-delay: .8s
}

@keyframes spinner_rcyq {
    0% {
        transform: translate(12px, 12px) scale(0);
        opacity: 1
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 0
    }
}

#avatarUpload {
    opacity: 0;
    position: absolute;
    top: -9999px;
}
.overlay-change-avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ddd;
    opacity: 0;
    /* transition: opacity var(--transition-200ms) ease-in-out; */
    cursor: pointer;
}
.overlay-change-avatar * {
    pointer-events: none;
}

.pos-center {
    position: absolute;
    font-size: 32px;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
}

.overlay-change-avatar.hover,
.overlay-change-avatar:hover {
    opacity: 1;
}

.home-container {
    min-height: 700px;
    transition: height 1s ease-in-out;
}
</style>

<script type="text/javascript">
import $ from "jquery";
import { getCustomer, setCustomer, updateAppearanceKnab, isKnab } from "@/App.vue";
import { useToast } from 'vue-toastification';

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
    name: 'home-component',
    data() {
        return {
            firstname: null,
            middlename: null,
            lastname: null,
            avatar: null,
            showAvatar: null,
            
            username: "",
            loadingCustomerdata: true,
            dashboardData: [],
            runningdisabilities: 0,
            countNewInvoices: 0,
            current_brand_id: undefined,

            title: undefined,
        }
    },
    methods: {
        isCrowdsurance() {
            return isKnab();
        },
        onDragenterAvatar() {
            $('.overlay-change-avatar').addClass('hover')

            console.log('enter');
        },
        onDragleaveAvatar() {
            $('.overlay-change-avatar').removeClass('hover')

            console.log('leave');
        },
        onChangeAvatarUpload(e) {
            this.uploadAvatar(e.target.files);

            console.log( e.target.files );
        },
        onDropAvatar(e) {
            this.uploadAvatar(e.dataTransfer.files)

            $('.overlay-change-avatar').removeClass('hover')

            console.log(e.dataTransfer.files);
            // emit('files-dropped', [...e.dataTransfer.files])
        },

        uploadAvatar(fileList) {
            if(fileList.length) {
                if(fileList[0].size > 5000000) {
                    const toast = useToast();
                    toast.error("Kies een afbeelding van max 5MB");
                } else {
                    let formData = new FormData()
                    formData.append('avatar', fileList[0])

                    // track status and upload file
                    // file.status = 'loading'
                    // let response = await fetch(url, { method: 'POST', body: formData })

                    this.$http.post('/my', formData)
                    .then((response) => {
                        setCustomer(response.data);
                        this.avatar = response.data.avatar;
                        if(this.avatar)
                            this.showAvatar = this.avatar;
                    })
                    .catch(function() {})
                }
            }

            $('#avatarUpload')[0].value = null;
            $('#avatarUpload')[0].files = new DataTransfer().files;
        },

        preventDefaults(e) {
            e.preventDefault()
        },
        async getCustomerData() {
            let customer = await getCustomer();

            // Persoonsgegevens
            this.firstname = customer.firstname;
            this.middlename = customer.middlename;
            this.lastname = customer.lastname;

            this.username = `${customer.firstname || ""} ${customer.middlename || ""} ${customer.lastname || ""}`;
            
            this.avatar = customer.avatar;
            this.current_brand_id = customer.current_brand_id;

            if(this.avatar)
                this.showAvatar = this.avatar;

            this.title = 'Mijn ondernemersvangnet';
            if(isKnab()) {
                updateAppearanceKnab();
                this.title = 'Mijn Knab Crowdsurance';

                if(this.showAvatar.includes("shared_images")) {
                    this.showAvatar = "shared_images/user_knab.png";
                }
            }

            // Verander tekst

            try {
                await this.$http.get('/dashboard')
                .then((response) => {
                    this.dashboardData = response.data;
                    this.$hasActions(response.data);

                    $(".dashboard-data").fadeIn('slow');

                    this.loadingCustomerdata = false;
                });
            } catch (error) {
                console.error(error)
            }

        },
    },
    mounted() {
        $(".dashboard-data").fadeOut(0);
        this.getCustomerData();
    
        events.forEach((eventName) => {
            document.body.addEventListener(eventName, this.preventDefaults)
        });

        if(!this.$isWebView()) {
            $(".overlay-change-avatar").on('mouseenter', function() {
                $(".gravatar").css("opacity", 0);
            });
            $(".overlay-change-avatar").on('mouseleave', function() {
                $(".gravatar").css("opacity", 1);
            });
        } else {
            $(".overlay-change-avatar").css("opacity", 0);
        }
    },
    unmounted() {
        events.forEach((eventName) => {
            document.body.removeEventListener(eventName, this.preventDefaults)
        })
    },
}
</script>
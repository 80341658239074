<template>
    <div class="full-mobile d-flex justify-content-center position-relative">
        <img src="@/assets/spinner.png" class="center-hub fa-spin" style="opacity: 0;">
        <div class="blurry" style="backdrop-filter: blur(0px);"></div>
        <div class="container-inputs p-3 m-0 text-center">
            <div class="row align-middle">
                <div class="justify-content-center">
                    <img src="@/assets/logo_zo_pincode.png" class="mb-3"><br/>
                    <h1 class="mt-2 mb-0 text-center text-white text-nowrap center-text h3 fw-bold">Mijn ondernemersvangnet</h1>
                </div>
                <div class="mt-5"></div>
                <div class="row justify-content-center m-0 my-5">
                    <div class="col h5 mb-0 text-light keypad-input" v-for="(inp, index) in input" :key="index">
                        <span class="circle">
                            <span class="filled empty"></span>
                            <span class="d-none">{{ inp }}</span>
                        </span>
                    </div>
                </div>
                <div class="col-12">
                    <h6 class="text-white message" v-if="!pincodeExists">Verzin een 5-cijferige pincode om in te loggen</h6>
                    <h6 class="text-white message" v-if="pincodeExists && !pincodeConfirmed">Voer de pincode nog een keer in om deze te bevestigen</h6>
                </div>
                <div class="col-4 overflow-hidden position-relative" v-for="(p, index) in keypad" :key="index">
                    <div class="keypad" :data-index="index" :data-number="p.number" @click="() => addNumber(p.number)">
                        <div class="touch"></div>
                        <h2 class="display-5 text-white text-center my-2">
                            <span v-if="p.number !== 'BACK'">{{ p.number }}</span>
                            <span v-else>
                                <i class="fas fa-backspace"></i>
                            </span>
                        </h2>
                        <p :class="['text-white text-center opacity-50', p.number === '' ? 'd-none' : '']">{{ p.tokens }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import bcrypt from 'bcryptjs';
import { useToast } from 'vue-toastification';

export default {
    name: "personalia-component",
    components: {},
    mounted() {
        this.$isWebView();
        $(".keypad").hide()

        $(".keypad").each(function() {
            let delay = parseInt($(this).data('index')) * 50;
            setTimeout(() => {
                $(this).show(400);
            }, delay)
        });

        $(".col-4").on('click', function() {
            $(this).children('.keypad').children('.touch').removeAttr('style');
            $(this).children('.keypad').children('.touch').css({
                "animation": "grow 400ms forwards",
            });
            setTimeout(() => {
                $(this).children('.keypad').children('.touch').removeAttr('style');
            }, 400);
        });
    },
    methods: {
        addNumber(num) {
            if(this.inputIndex < 5) {
                // Trilling triggeren
                const url = new URL(location);
                url.searchParams.set("lastkey", this.inputIndex);
                history.replaceState({}, null, url);

                if(num === 'BACK') {
                    this.inputIndex--;
                    this.input[this.inputIndex] = null;
                    $(`.keypad-input:nth-child(${this.inputIndex + 1}) .filled`).addClass('empty');
                } else {
                    this.input[this.inputIndex] = num;
                    $(`.keypad-input:nth-child(${this.inputIndex + 1}) .filled`).removeClass('empty');
                    this.inputIndex++;
                }
                this.inputIndex = Math.min(this.inputIndex, 5);
                this.inputIndex = Math.max(this.inputIndex, 0);

                if(this.inputIndex === 5) {
                    $(".keypad").addClass("opacity-25");
                    // Hash dit!
                    
                    if(!this.pincodeExists) {
                        $(".filled").addClass("empty");
                        $(".keypad").removeClass("opacity-25");

                        this.inputIndex = 0;

                        let pincode = this.input.join("");
                        let salt = bcrypt.genSaltSync(10);

                        this.pincodeExists = true;

                        localStorage.setItem("pincode", bcrypt.hashSync(pincode, salt));
                        $(".message").text("Voer de pincode nog een keer in om deze te bevestigen");

                        const toast = useToast();
                        toast.success("Pincode succesvol aangemaakt!");
                        return;
                    } else if(this.pincodeExists && !this.pincodeConfirmed) {
                        $(".filled").addClass("empty");
                        $(".keypad").removeClass("opacity-25");

                        let pincode = this.input.join("");
                        let pincodeFromStorage = localStorage.getItem('pincode');

                        this.inputIndex = 0;

                        if(bcrypt.compareSync(pincode, pincodeFromStorage)) {
                            const toast = useToast();
                            toast.success("Pincode bevestigd!");
                            localStorage.setItem('pincode_confirmed', true);
                            this.pincodeConfirmed = true;
                            $(".message").hide('fast');
                            // Nu je hem hebt bevestigs mag je wel door
                            this.checkPinCode();
                        } else {
                            const toast = useToast();
                            localStorage.removeItem('pincode_confirmed');
                            localStorage.removeItem('pincode');
                            this.pincodeExists = false;
                            this.pincodeConfirmed = false;
                            toast.error("De pincode komt niet overeen. Probeer het opnieuw.");
                            $(".message").text("Verzin een 5-cijferige pincode om in te loggen");
                        }
                        return;
                    }
                    this.checkPinCode();
                }
            }
        },
        checkPinCode() {
            $(".center-hub").css({
                "opacity": 1,
            });
            $(".blurry").css({
                "backdrop-filter": "blur(10px)",
            });
            setTimeout(() => {
                let pincode = localStorage.getItem('pincode');
                let pincodeEntry = this.input.join("");
                let isCorrect = bcrypt.compareSync(pincodeEntry, pincode);

                if(!isCorrect) {
                    this.inputIndex = 0;
                    this.input = [null, null, null, null, null];
                    $(".filled").addClass("empty");
                    $(".keypad").removeClass("opacity-25");

                    const toast = useToast();
                    toast.error("De ingevoerde pincode is onjuist.");
                    $(".center-hub").css({
                        "opacity": 0,
                    });
                    $(".blurry").css({
                        "backdrop-filter": "blur(0)",
                    });
                    return;
                }
                localStorage.setItem('pincode_login', true);
                history.replaceState({}, null, window.location.href.split("?")[0]);
                this.$router.push('/');
            }, 1000);
        }
    },
    data() {
        return {
            pincodeExists: localStorage.getItem("pincode"),
            pincodeConfirmed: localStorage.getItem("pincode_confirmed"),
            inputIndex: 0,
            input: [null, null, null, null, null],
            keypad: [
                {
                    number: "1",
                    tokens: "",
                },
                {
                    number: "2",
                    tokens: "A B C",
                },
                {
                    number: "3",
                    tokens: "D E F",
                },
                {
                    number: "4",
                    tokens: "G H I",
                },
                {
                    number: "5",
                    tokens: "J K L",
                },
                {
                    number: "6",
                    tokens: "M N O",
                },
                {
                    number: "7",
                    tokens: "P Q R S",
                },
                {
                    number: "8",
                    tokens: "T U V",
                },
                {
                    number: "9",
                    tokens: "W X Y Z",
                },
                {
                    number: "",
                    tokens: "0",
                },
                {
                    number: "0",
                    tokens: ""
                },
                {
                    number: "BACK",
                    tokens: "",
                }
            ]
        }
    }
}
</script>
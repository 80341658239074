import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import SaldoPage from '@/pages/SaldoPage.vue'
import ProductsPage from '@/pages/ProductsPage.vue'
import ProductsChangePage from '@/pages/ProductsChangePage.vue'
import ProductsCrowdsuranceChange from '@/pages/ProductsCrowdsuranceChange.vue'
import ProductsToZo from '@/pages/ProductsToZo.vue'
import DisabilityPage from '@/pages/DisabilityPage.vue'
import NewsPage from '@/pages/NewsPage.vue'
import NewsDetailPage from '@/pages/NewsDetailPage.vue'
import PersonaliaPage from '@/pages/PersonaliaPage.vue'
import AccountPage from '@/pages/AccountPage.vue'
import SetupAuthenticatorPage from '@/pages/auth/setup/SetupAuthenticatorPage.vue'
import SetupYubikeyPage from '@/pages/auth/setup/SetupYubikeyPage.vue'
import DisabilityUpdatePage from '@/pages/DisabilityUpdatePage.vue'
import NewDisabilityPage from '@/pages/NewDisabilityPage.vue'
import GiftsPage from '@/pages/GiftsPage.vue'
import LoginPage from '@/pages/auth/LoginPage.vue'
import ImpersonatePage from '@/pages/auth/ImpersonatePage.vue'
import SmsPage from '@/pages/auth/SmsPage.vue'
import YubikeyPage from '@/pages/auth/YubikeyPage.vue'
import AuthenticatorPage from '@/pages/auth/AuthenticatorPage.vue'
import ForgotPasswordPage from '@/pages/auth/ForgotPasswordPage.vue'
import ResetPasswordPage from '@/pages/auth/ResetPasswordPage.vue'
import WithdrawSaldoPage from '@/pages/WithdrawSaldoPage.vue'
import WithdrawConfirmationPage from '@/pages/WithdrawConfirmationPage.vue'
import InvoicesPage from '@/pages/InvoicesPage.vue'
import AOPage from '@/pages/AOPage.vue'
import RetirementPage from '@/pages/RetirementPage.vue'
import DisabilityConfirmationPage from '@/pages/DisabilityConfirmationPage.vue'
import OpenDirectdebitsPage from '@/pages/OpenDirectdebitsPage.vue'
import MessagesPage from '@/pages/MessagesPage.vue'
import Error404Page from '@/pages/Error404Page.vue'
import ProductsCrowdsurancePage from '@/pages/ProductsCrowdsurancePage.vue'
import PinCodePage from '@/pages/PinCodePage.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return {
      top: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/saldo',
      name: 'Mijn spaarpot',
      component: SaldoPage
    },
    {
      path: '/saldo/withdraw',
      name: 'Saldo opnemen',
      component: WithdrawSaldoPage
    },
    {
      path: '/withdrawal-confirmation',
      name: 'Bevestiging saldo opnemen',
      component: WithdrawConfirmationPage
    },
    {
      path: '/gifts',
      name: 'Schenkingen',
      component: GiftsPage
    },
    {
      path: '/products',
      name: 'Mijn producten',
      component: ProductsPage
    },
    {
      path: '/products/change',
      name: 'Producten wijzigen',
      component: ProductsChangePage
    },
    {
      path: '/products-crowdsurance/change',
      name: 'Crowdsurance wijzigen',
      component: ProductsCrowdsuranceChange
    },
    {
      path: '/products-crowdsurance',
      name: 'Crowdsurance',
      component: ProductsCrowdsurancePage,
    },
    {
      path: '/products/to-zo',
      name: 'Overstappen',
      component: ProductsToZo
    },
    {
      path: '/disability',
      name: 'Mijn ziekmeldingen',
      component: DisabilityPage
    },
    {
      path: '/disability/new',
      name: 'Nieuwe ziekmelding',
      component: NewDisabilityPage
    },
    {
      path: '/disability/confirmation/:id',
      name: 'Ziekmelding toegevoegd',
      component: DisabilityConfirmationPage
    },
    {
      path: '/disability/update/:id',
      name: 'Ziekmelding details',
      component: DisabilityUpdatePage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/open-directdebits',
      name: 'Openstaande inleg',
      component: OpenDirectdebitsPage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/retirement-account',
      name: 'Pensioenspaarrekening aanvragen',
      component: RetirementPage
    },
    {
      path: '/news',
      name: 'Nieuws',
      component: NewsPage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/news/:id',
      name: 'Nieuws artikel',
      component: NewsDetailPage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/invoices',
      name: 'Administratieoverzicht',
      component: InvoicesPage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/messages',
      name: 'Berichten',
      component: MessagesPage,
      meta: {
        affectingDashboardCounters: true,
      },
    },
    {
      path: '/personalia',
      name: 'NAW gegevens',
      component: PersonaliaPage
    },
    {
      path: '/pincode',
      name: 'Pincode scherm',
      component: PinCodePage,
    },

    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/impersonate',
      name: 'Impersonate',
      component: ImpersonatePage,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'Wachtwoord vergeten',
      component: ForgotPasswordPage,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/reset-password',
      name: 'Wachtwoord reset',
      component: ResetPasswordPage,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/2fa/sms',
      name: 'sms',
      component: SmsPage
    },
    {
      path: '/ao-percentage',
      name: 'Statistieken',
      component: AOPage
    },
    {
      path: '/account',
      name: 'Account',
      component: AccountPage
    },
    {
      path: '/account/setup-authenticator',
      name: 'Authenticator instellen',
      component: SetupAuthenticatorPage
    },
    {
      path: '/account/setup-yubikey',
      name: 'Yubikey instellen',
      component: SetupYubikeyPage
    },
    {
      path: '/2fa/authenticator',
      name: 'Authenticator',
      component: AuthenticatorPage
    },
    {
      path: '/2fa/yubikey',
      name: 'Yubikey',
      component: YubikeyPage
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: Error404Page
    },
  ]
});

export default router
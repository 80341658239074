import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import './index.css'
import $cookies from 'vue-cookies';
import mitt from 'mitt';
import Toast, { useToast }  from "vue-toastification";

import $ from "jquery";
import axios from './plugins/axios';

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const emitter = mitt()

const numberFormatEuro = function (amount, decimals = 2) {
    return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: decimals, 
        minimumFractionDigits: decimals,
     }).format(amount).replace(/\s/g, '');
}

const isWebView = function() {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const webview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

    // Opschonen voor WebView
    localStorage.setItem('is_webview', false);
    if(webview) {
        // Footer
        $(".footer").addClass('d-none');

        // Kruimelpad
        $(".breadcrumbs").removeClass('d-flex');
        $(".breadcrumbs").addClass('d-none');
        $(".yubikey").addClass("d-none");

        localStorage.setItem('is_webview', true);
        return true;
    }
    return false;
}

const canUsePagination = function (url, label) {
    if (label === "...")
        return false;

    if ((label === "<" || label === ">") && url === null)
        return false;

    return true;
}

const formatDate = function (date, time = false) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    // use non breaking hypen
    let str = [day, month, year].join('‑');

    if (time) {
        let hours = d.getHours() < 10 ? '0' + d.getHours() : '' + d.getHours(),
            minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : '' + d.getMinutes();
        str += ' ' + [hours, minutes].join(':');
    }
    return str;
}

const createModal = function (title, content, button_icon, button_text, url, modal_type, question_enum = "reportBetter", additional_class = '') {
    let obj = {
        title: title,
        content: content,
        button_icon: button_icon,
        button_text: button_text,
        url: url,
        modal_type: modal_type,
        question_enum: question_enum,
        additional_class: additional_class
    };
    // this.$emit('createToast', obj)
    return obj;
}

// Verwacht wel een object volgens de dashboardData interface
const hasActions = function(data) {
    let total = data.count_new_invoices
        + data.count_new_messages
        + data.count_open_directdebits
        + data.count_update_expected_disabilities
        + data.count_new_news_articles;

    if(!total) {
        $(".notification").hide();
        $(".has-actions").hide();
        return;
    }

    $(".has-actions").show();

    if(data.count_new_invoices || data.count_open_directdebits) {
        $(".open-administration").show();
        $(".open-administration").html(data.count_new_invoices + data.count_open_directdebits);
    } else {
        $(".open-administration").hide();
    }

    if(data.count_new_messages) {
        $(".new-messages").show();
        $(".new-messages").html(data.count_new_messages);
    } else {
        $(".new-messages").hide();
    }

    if(data.count_update_expected_disabilities) {
        $(".open-disabilities").show();
        $(".open-disabilities").html(data.count_update_expected_disabilities);
    } else {
        $(".open-disabilities").hide();
    }

    if(data.count_new_news_articles) {
        $(".new-news").show();
        $(".new-news").html(data.count_new_news_articles);
    } else {
        $(".new-news").hide();
    }
}

const html_entity_decode = function(html) {
    return $('<textarea />').html(html).text();
}

const ModalType = {
    Confirmation: 0,
    Notification: 1,
    Question: 2,
};

// Verberg bolletjes en notificaties totdat deze getoond moeten worden
$(".has-actions").hide();
$(".notification").hide();

const app = createApp(App)
app.config.globalProperties.$authenticated = false;
app.config.globalProperties.$ModalType = ModalType;
app.config.globalProperties.$cookies = $cookies;
app.config.globalProperties.$hasActions = hasActions;

router.afterEach((to, from) => {
    if(('affectingDashboardCounters' in from.meta && from.meta.affectingDashboardCounters) || ('affectingDashboardCounters' in to.meta && to.meta.affectingDashboardCounters)) {
        app.config.globalProperties.$http.get('/dashboard')
            .then((response) => {
                // Deze functie verzorgt de opmaak van het hamburgermenu met een bolletje en het aantal notificiaties voor de gebruiker
                hasActions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
});

router.beforeEach((to) => {
    let isKnab = false;
    
    let customerStorage = localStorage.getItem('customerStorage') === "localStorage" ? localStorage : sessionStorage

    let c = customerStorage.getItem('customer');
    if(c) {
        try {
            c = JSON.parse(c);
            isKnab = c.current_brand_id === 4;
        } catch(e) {
            console.log(e);
        }
    }
        
    if('isPublic' in to.meta && to.meta.isPublic) {
        $(".hamburger-authentication").hide();
        $(".has-actions").hide();
    
        if(to && to.name) {
            document.title = to.name + ` | ${ isKnab? "Mijn Knab Crowdsurance" : "Mijn Ondernemersvangnet" }`;
        } else {
            document.title = `${ isKnab ? "Mijn Knab Crowdsurance" : "Mijn Ondernemersvangnet" }`;
        }
        return true;
    }

    
    // Check doen om validiteit van een JWT te controleren (is deze niet expired)
    const token = customerStorage.getItem("token");

    // Als er geen token bestaat, re-routen naar inloggen
    if (!token) {
        localStorage.clear();
        sessionStorage.clear();

        $(".hamburger-authentication").hide();
        $(".has-actions").hide();

        router.push('/login');
        return false;
    } else {
        // Check validiteit van JWT
        try {
            const jwt = parseJwt(token);
            const now = Date.now();

            if (jwt.exp > now) {
                localStorage.clear();
                sessionStorage.clear();

                const toast = useToast();
                toast.error("De sessie is verlopen, log opnieuw in");

                // TODO, melding token verlopen
                $(".hamburger-authentication").hide();
                $(".has-actions").hide();
                
                router.push('/login');
                return false;
            }

            // app.config.globalProperties.$authenticated = true;

            // TODO: verberg hamburgermenu ook als de gebruiker is ingelogd maar nog niet met 2fa
            const routes2fa = ['/2fa/yubikey', '/2fa/authenticator', '/2fa/sms'];
            if(routes2fa.indexOf(to.path) === -1)
                $(".hamburger-authentication").show();
        } catch (e) {
            console.log(e);
        }
    }

    if(isWebView()) {
        let has2FA = false;
        try {
            const jwt = parseJwt(token);
            if(jwt.scopes.length) {
                has2FA = true;
            }
        } catch(e) {
            console.log(e);
        }

        if(has2FA) {
            let pincode_login = localStorage.getItem('pincode_login');
            // De Mollie status is als de URL overeenkomt met de redirect URL van Mollie. Dan hoef je je pincode niet nog een keer in te voeren       
            let molliePaymentIds = localStorage.getItem('mollie_payment_ids');
            let pincodeRedirectUrl = molliePaymentIds && window.location.href.includes("/open-directdebits?check=" + molliePaymentIds ?? '');

            if((!pincode_login || pincode_login === 'false')) {
                if(!pincodeRedirectUrl) {
                    if(to.path != '/pincode')
                        router.push('/pincode');
                } else {
                    localStorage.setItem('pincode_login', true);
                    localStorage.removeItem('mollie_payment_ids');
                }
            }

            if(to.path === '/pincode') {
                $(".top-bar").addClass("d-none");
                $(".footer").addClass("d-none");
                $(".content").css({
                    "margin-top": 0,
                });
            } else {
                $(".top-bar").removeClass("d-none");
                $(".footer").removeClass("d-none");
                $(".content").css({
                    "margin-top": 60,
                });
            }
        }
    } else {
        if(to.path === '/pincode') {
            router.push('/');
        }
    }
    
    if(to && to.name) {
        document.title = to.name + ` | ${ isKnab ? "Mijn Knab Crowdsurance" : "Mijn Ondernemersvangnet" }`;
    } else {
        document.title = `${ isKnab ? "Mijn Knab Crowdsurance" : "Mijn Ondernemersvangnet" }`;
    }
});

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

// Globale methoden voor gebruik in verschillende pagina's en componenten
app.config.globalProperties.$numberFormatEuro = numberFormatEuro;
app.config.globalProperties.$canUsePagination = canUsePagination;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$createModal = createModal;
app.config.globalProperties.$html_entity_decode = html_entity_decode;
app.config.globalProperties.$isWebView = isWebView;

window.emitter = emitter;
app.config.globalProperties.emitter = emitter;

app.use(Toast);
app.use(axios);
app.use(router)

app.mount('#app')
